<template>
  <v-card class="mt-n10 moholand-shadow">
    <v-card-title>
      ایجاد کاربر جدید
      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        @click="
          $router.replace({
            path: destinationRoute,
            query: $route.query,
          })
        "
      >
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />
        <v-row align="center" justify="end" class="mt-8">
          <v-btn @click="clear" depressed>
            پاک کردن فرم
          </v-btn>

          <v-btn
            class="mx-4"
            dark
            color="success"
            :loading="loading"
            type="submit"
          >
            ایجاد کاربر جدید
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { formPostPrepare } from "@/constants/PanelFormHandler";
export default {
  components: {
    VFormBase,
  },
  data() {
    return {
      rules: Constants.rules,
      // destinationRoute:
      //   this.$route.fullPath.slice(
      //     0,
      //     this.$route.fullPath.indexOf("create") - 1
      //   ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      destinationRoute: this.$route.fullPath.slice(
        0,
        this.$route.fullPath.indexOf("create") - 1
      ),
      loading: false,
      model: {
        first_name: "",
        last_name: "",
        cellphone: "",
        avatar: "",
        email: "",
        type: null,
        birthday: "",
        store_name: "",
        desc: "",
      },
      schema: {
        avatar: {
          type: "MyAvatarPicker",
          required: true,
          col: { cols: 12 },
        },
        first_name: {
          type: "CustomInput",
          label: "نام",
          rules: [Constants.rules.required],
          required: true,
        },
        last_name: {
          type: "CustomInput",
          label: "نام خانوادگی",
          rules: [Constants.rules.required],
          required: true,
        },
        cellphone: {
          type: "CustomInput",
          label: "موبایل",
          rules: [Constants.rules.required],
          required: true,
        },
        email: {
          type: "CustomInput",
          label: "ایمیل",
          required: true,
        },
        birthday: {
          type: "ModalDatePicker",
          inputType: "select",
          label: "تاریخ تولد",
          required: true,
        },
        type: {
          type: "CustomInput",
          inputType: "select",
          items: [
            { id: "admin", value: "مدیر کل" },
            { id: "user", value: "موهولندی" },
            { id: "vendor", value: "فروشنده" },
            { id: "instructor", value: "مدرس" },
          ],
          itemValue: "id",
          itemText: "value",
          label: "نوع کاربری",
          required: true,
        },
        store_name: {
          type: "CustomInput",
          label: "نام فروشگاه",
          rules: [],
          persistentHint: true,
          hint: "این فیلد برای نوع کاربری فروشنده فعال است.",
          disabled: true,
          col: { cols: 12 },
        },
        desc: {
          type: "CustomInput",
          inputType: "textarea",
          label: "درباره این کاربر",
          col: { cols: 12 },
        },
      },
    };
  },
  created() {
    // this.getData();
  },

  watch: {
    "model.type"(val) {
      if (val == "vendor") {
        this.schema.store_name.disabled = false;
        this.schema.store_name.hint = "";
        this.schema.store_name.rules = [Constants.rules.required];
      } else {
        this.schema.store_name.disabled = true;
        this.schema.store_name.hint =
          "این فیلد برای نوع کاربری فروشنده فعال است.";
        this.schema.store_name.rules = [];
      }
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let data = formPostPrepare(this.model);

        MyAxios.post("users/create", data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "کاربر با موفقیت ایجاد شد.",
              type: "success",
            });
            console.log(response);
            this.$router.replace({
              path: this.destinationRoute,
              query: this.$route.query || {},
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "مشکلی پیش آمده است. لطفا دوباره سعی کنید",
            });
            console.log(error);
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
    clear() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
